import { formatAmount } from 'components/Strapi/StrapiPlanHero'
import { useMemo } from "react"

interface IUseStrapiShopProductHook {
  product: Strapi.ShopProduct
}

export const findPriceForCurrency = (
  sku: Strapi.ShopProductSKU,
  currency: string
) => {
  return sku.pricings.find(
    d => d.currency.toLocaleLowerCase() === currency.toLocaleLowerCase()
  )
}

const filterProduct = (
  product: Strapi.ShopProduct,
  country?: Strapi.Country
) => ({
  ...product,
  skus: product.skus
    .map(sku => {
      return {
        ...sku,
        pricings: sku.pricings.filter(pricing => {
          return (
            country &&
            pricing.countries.map(c => c.locale).indexOf(country.locale) >= 0
          )
        }),
      }
    })
    .filter(sku => sku.pricings.length > 0),
})

interface IFormattedShopPricing extends Strapi.ShopProductPrice {
  readableAmount?: string
}

interface IFormattedShopProductSKU extends Strapi.ShopProductSKU {
  pricings: IFormattedShopPricing[]
}

interface IFormattedShopProduct extends Strapi.ShopProduct {
  skus: IFormattedShopProductSKU[]
}

export const formatProductForCountry = (
  product: Strapi.ShopProduct,
  country?: Strapi.Country
): IFormattedShopProduct => {
  if (
    product.digital &&
    country &&
    country.currencyCode &&
    country.conversionRateToUSD
  ) {
    // Add pricing for country
    const skus = product.skus
      .map(sku => {
        let usdPrice
        let regularPrice
        if (country && country.currencyCode) {
          regularPrice = findPriceForCurrency(sku, country.currencyCode)
        }
        if (sku.sku !== "escape-from-conform-prison-gift-voucher") {
          usdPrice = findPriceForCurrency(sku, "USD")
        }
        if (!usdPrice && !regularPrice) return null
        const amount = regularPrice?.amount ||
        Math.round(
          (country.conversionRateToUSD as number) * usdPrice.amount
        )
        return {
          ...sku,
          pricings: [
            {
              amount,
              readableAmount: formatAmount(country.currencyCode, amount),
              currency: country.currencyCode as string,
              countries: [country],
            },
          ] as IFormattedShopPricing[],
        }
      })
      .filter(d => d && d?.pricings.length > 0) as Strapi.ShopProductSKU[] 
    return { ...product, skus }
  }

  return filterProduct(product, country)
}

const useStrapiShopProduct = (
  inputProduct: Strapi.ShopProduct,
  country: Strapi.Country
): IUseStrapiShopProductHook => {
  const product = useMemo(() => {
    const formatted = formatProductForCountry(inputProduct, country)

    return {
      ...formatted,
    }
  }, [inputProduct])

  return {
    product,
  }
}

export default useStrapiShopProduct
