import { useLocation } from "@reach/router"
import LanguageDropdown from "components/LanguageDropdown"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Menu } from "grommet-icons"
import { IconButton, Shop } from "nzk-react-components"
import React, { useMemo, useState } from "react"
import styled, { css } from "styled-components"
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import { formatProductForCountry } from "../../hooks/useStrapiShopProduct"
import { useShopLocaleState } from "../../providers/ShopLocaleProvider"

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  color: #662d91;
  height: 50px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  padding: 0 30px;
`

const Items = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  @media (max-width: 650px) {
    display: none;
  }
`

const Item = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px;
  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      ::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #662d91;
      }
    `}
`

const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  > * {
    margin-right: 15px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const MobileNav = styled.div`
  position: relative;
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
`

const Trigger = styled.div`
  cursor: pointer;
`

const MobileItem = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      background-color: #9026c1;
    `}
`

const MobileNavContent = styled.div`
  position: absolute;

  bottom: 0;
  left: 0;
  width: 250px;
  transform: translate(0%, 100%);
  border-radius: 6px;
  background: #701ea8;
  background: #662d91;
  border: 4px solid #9026c1;
  border: 3px solid #9026c1;
  box-shadow: 0 4px 0 #4e1b6d, 0 10px 0 rgba(0, 0, 0, 0.4);
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  > * {
    border-bottom: 2px solid #9026c1;
    :hover {
      background-color: #9e40ca;
    }
  }
  > :last-child {
    border-bottom: none;
  }
  ${(props: { opened: boolean }) =>
    !props.opened &&
    css`
      display: none;
    `}
`

const ShopHeader = ({ noCheckout }: { noCheckout?: boolean }) => {
  const location = useLocation()
  const { cartCount } = useShoppingCart()
  const { locale, onLanguageChanged } = useShopLocaleState()
  const [mobileNavOpened, setMobileNavOpened] = useState(false)

  const { allStrapiShopCategories, allStrapiCountries } =
    useStaticQuery(graphql`
      {
        allStrapiCountries {
          edges {
            node {
              locale
              name
              currencyCode
              conversionRateToUSD
            }
          }
        }
        allStrapiShopCategories {
          edges {
            node {
              title
              slug
              products {
                id
                available
                skus {
                  pricings {
                    id
                    countries {
                      locale
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)

  const country = allStrapiCountries.edges.find(
    ({ node }) => node.locale === locale
  )

  const filteredCategories = useMemo(
    () =>
      (allStrapiShopCategories.edges || [])
        .filter(
          ({ node }) =>
            node.products.map(p => formatProductForCountry(p, country)).length >
            0
        )
        .map(({ node }) => node),
    [allStrapiShopCategories, locale]
  )

  const selectedSlug = useMemo(() => {
    if (location.pathname === "/shop" || location.pathname === "/shop/")
      return "home"
    const categories = filteredCategories.filter(category => {
      if (new RegExp(category.slug).test(location.pathname)) {
        return true
      }
      return false
    })
    return categories[0]?.slug
  }, [filteredCategories, location])

  const COUNTRIES = allStrapiCountries.edges.reduce((acc, { node }) => {
    acc[node.locale] = node
    return acc
  }, {})

  return (
    <div>
      <Wrapper>
        <MobileNav>
          <Trigger onClick={() => setMobileNavOpened(d => !d)}>
            <Menu color="#662D91" />
          </Trigger>
          <MobileNavContent opened={mobileNavOpened}>
            <Link to="/shop">
              <MobileItem selected={selectedSlug === "home"}>Home</MobileItem>
            </Link>
            {filteredCategories.map(category => (
              <Link
                key={category.slug}
                to={`/shop/categories/${category.slug}`}
              >
                <MobileItem selected={selectedSlug === category.slug}>
                  {category.title}
                </MobileItem>
              </Link>
            ))}
          </MobileNavContent>
        </MobileNav>
        <Items>
          <Link to="/shop">
            <Item selected={selectedSlug === "home"}>Home</Item>
          </Link>
          {filteredCategories.map(category => (
            <Link key={category.slug} to={`/shop/categories/${category.slug}`}>
              <Item selected={selectedSlug === category.slug}>
                {category.title}
              </Item>
            </Link>
          ))}
        </Items>
        <LanguageDropdown
          noTarget
          onChange={onLanguageChanged}
          locales={Object.keys(COUNTRIES)}
          currentLocale={locale}
        />
        {!noCheckout && (
          <Actions>
            {cartCount > 0 ? (
              <Link to="/shop/checkout">
                <IconButton
                  size="x-small"
                  icon={<Shop width="30px" height="30px" />}
                  theme="purple"
                >
                  Checkout {cartCount} item{cartCount > 1 ? "s" : ""}
                </IconButton>
              </Link>
            ) : (
              <IconButton
                onClick={() => {}}
                size="x-small"
                icon={<Shop width="30px" height="30px" />}
                theme="purple"
              >
                Empty
              </IconButton>
            )}
          </Actions>
        )}
      </Wrapper>
    </div>
  )
}

ShopHeader.defaultProps = {
  noCheckout: false,
}

export default ShopHeader
